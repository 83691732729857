
.button {
  width: 50px;
  max-height: 50px;
  margin: 10px;
  font-size:30px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#d7d7d7));
  background-image: -webkit-linear-gradient(top, #f7f7f7, #d7d7d7); 
  background-image: -moz-linear-gradient(top, #f7f7f7, #d7d7d7); 
  background-image: -ms-linear-gradient(top, #f7f7f7, #d7d7d7); 
  background-image: -o-linear-gradient(top, #f7f7f7, #d7d7d7);
}