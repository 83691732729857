
.mainContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.column { 
  width: 50%;
  flex-direction: column;
  flex-wrap: nowrap;
  border-left: 1px solid;
  border-right: 1px solid;
  align-items: center;
}

.decimal {  
  font-size: 30px;
  justify-content: center;
}


.mixed {  
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 30px;
}


.fraction {  
  width: 50%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}


.divisor {  
  border-top: 1px solid;
}


