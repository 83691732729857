.chart { 
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  min-width: 0px;
}




